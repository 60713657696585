// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-h-js": () => import("./../../../src/pages/H.js" /* webpackChunkName: "component---src-pages-h-js" */),
  "component---src-pages-i-js": () => import("./../../../src/pages/I.js" /* webpackChunkName: "component---src-pages-i-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/R.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-s-js": () => import("./../../../src/pages/S.js" /* webpackChunkName: "component---src-pages-s-js" */),
  "component---src-pages-t-js": () => import("./../../../src/pages/T.js" /* webpackChunkName: "component---src-pages-t-js" */)
}

